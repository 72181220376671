import { useCallback, useEffect } from 'react';
import { RequestPolicy, UseQueryExecute } from 'urql';

type UsePollingArgs = {
  intervalMs: number;
  enabled: boolean;
  refetches: UseQueryExecute[];
  requestPolicy?: RequestPolicy;
};
export const usePolling = ({ intervalMs: interval, enabled, refetches, requestPolicy }: UsePollingArgs) => {
  const doRefetches = useCallback(() => {
    refetches.forEach((refetch) => refetch(requestPolicy ? { requestPolicy } : undefined));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...refetches]);
  useEffect(() => {
    if (enabled) {
      const timerId = setInterval(doRefetches, interval);
      return () => {
        clearInterval(timerId);
      };
    }
  }, [interval, enabled, doRefetches]);
};
